import React from "react";
import StarCanvas from "../../../ui/StarCanvas";
import FooterContent from "./FooterContent";

const Footer = () => {
    return (
        <section className="relative z-0 pt-10 tab:pt-0">
            <div className="pointer-events-none absolute left-0 top-0 z-[1] h-screen w-screen opacity-25 tab:opacity-70">
                <StarCanvas />
            </div>

            <FooterContent />
            <div className="h absolute bottom-0 left-0 right-0 top-0 z-[-1] bg-[rgba(99,91,255,0.12)]"></div>
        </section>
    );
};

export default Footer;
