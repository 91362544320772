const SERVER_TYPES = {
    MT4: "mt4",
    MT5: "mt5"
};

const BROKER_TYPES = {
    // INCENTECO: 2,
    FUNDEDNEXT: 2,
    EIGHTCAP: 1
};

const TESTIMONIALS_PLATFORMS = {
    TWITTER:
        "https://fundednext.fra1.cdn.digitaloceanspaces.com/twitter-icon.png",
    TRUSTPILOT:
        "https://fundednext.fra1.digitaloceanspaces.com/trust-pilot-icon.png",
    YOUTUBE:
        "https://fundednext.fra1.cdn.digitaloceanspaces.com/youtube-icon.png",
    INSTAGRAM:
        "https://fundednext.fra1.cdn.digitaloceanspaces.com/instragram-icon.png"
};

const ACCOUNT_TYPES = {
    SWAP: "swap",
    SWAP_FREE: "swap-free"
};

const SWAP_FREE_PERCENTAGE = 10;

const PACKAGE_NAMES = {
    EXPRESS_NO_CONSISTENT: "express_nca",
    EXPRESS_CONSISTENT: "express",
    EVALUATION: "evaluation",
    STELLAR_STEP_ONE: "stellar_step_one",
    STELLAR_STEP_TWO: "stellar_step_two",
    STELLAR_LITE_STEP_ONE: "stellar_lite_step_one",
    STELLAR_LITE_STEP_TWO: "stellar_lite_step_two"
};

const LANGUAGES = [
    {
        code: "en",
        custom_flag: "https://cdn.weglot.com/flags/circle/gb.svg"
    },
    {
        code: "hi",
        custom_flag: "https://cdn.weglot.com/flags/circle/in.svg"
    },
    {
        code: "es",
        custom_flag: null
    },
    {
        code: "fa",
        custom_flag:
            "https://cdn.weglot.com/custom-flags/3487905_1695201672.svg"
    },
    {
        code: "ar",
        custom_flag: null
    },
    {
        code: "fr",
        custom_flag: null
    },
    {
        code: "nl",
        custom_flag: null
    },
    {
        code: "pt",
        custom_flag: null
    },
    {
        code: "de",
        custom_flag: null
    },
    {
        code: "th",
        custom_flag: null
    },
    {
        code: "ms",
        custom_flag: null
    },
    {
        code: "id",
        custom_flag: null
    },
    {
        code: "tr",
        custom_flag: null
    },
    {
        code: "pl",
        custom_flag: null
    },
    {
        code: "it",
        custom_flag: null
    }
];

module.exports = {
    SERVER_TYPES,
    BROKER_TYPES,
    TESTIMONIALS_PLATFORMS,
    ACCOUNT_TYPES,
    SWAP_FREE_PERCENTAGE,
    PACKAGE_NAMES,
    LANGUAGES
};