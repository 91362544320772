import { usePathname } from "next/navigation";
import React from "react";

const NavToggler = ({ callback, isOpen, isDark }) => {
    const barClasses = [
        isOpen ? "rotate-45" : "rotate-0",
        isOpen ? "-my-[1px] scale-0" : "my-1 scale-100",
        isOpen ? "-rotate-45" : "rotate-0"
    ];

    const textClasses = [
        {
            label: "Menu",
            classNames: isOpen
                ? "invisible leading-[0] opacity-0"
                : "visible leading-none opacity-100"
        },
        {
            label: "Close",
            classNames: isOpen
                ? "visible leading-none opacity-100"
                : "invisible leading-[0] opacity-0"
        }
    ];

    const renderBars = () => {
        const commonClasses = "block h-[1px] w-4 rounded-[30px] bg-white";
        return barClasses.map((classNames, idx) => (
            <span className={`${commonClasses} ${classNames}`} key={idx}></span>
        ));
    };

    const renderTexts = () =>
        textClasses.map((item) => (
            <span className={`block ${item.classNames}`} key={item.label}>
                {item.label}
            </span>
        ));

    const isDarkBackground = usePathname() === "/home-page";

    return (
        <>
            <button
                className={`inline-flex h-[36px] items-center gap-2 rounded-[30px] px-4 ${isDark ? "bg-white/10" : "bg-black"}`}
                onClick={callback}
            >
                <span className="max-w-4 relative">{renderBars()}</span>
                <span
                    className={`font-regular inline-block text-sm text-white/75`}
                >
                    {renderTexts()}
                </span>
            </button>
        </>
    );
};

export default NavToggler;
