/**
 * Title: useMediaQuery Hook
 * Description: This Hook check if the media query is valid or not
 * Author: M.h Sajjad Hossain Ripon
 * Data: Wed,2022-06-22
 * Time: 17:09:16.000-05:00
 */
//dependency's
import { useEffect, useState } from "react";

//main hook starts
const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        window.addEventListener("load", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
};

export default useMediaQuery;
