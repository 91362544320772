import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "../../redux/slices/toastReducer";

export default function ToastContainer() {
    const [isDomLoaded, setIsDomLoaded] = useState(false);
    const toast = useSelector((state) => state.toast);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsDomLoaded(true);
    }, []);

    useEffect(() => {
        const timerId = setTimeout(() => {
            dispatch(closeToast());
        }, toast.timeout);
        return () => clearTimeout(timerId);
    }, [dispatch, toast.timeout]);

    return <>{isDomLoaded && toast.component}</>;
}
