export const darkNavPaths = [
    "/",
    "/es",
    "/fr",
    "/fa",
    "/it",
    "/ar",
    "/pt",
    "/de",
    "/pl",
    "/nl",
    "/tr",
    "/id",
    "/th",
    "/ms",
    "/hi",
    "/stellar-model",
    "/affiliate-competition",
    "/competition",
    "/events",
    "/proprietary-trading",
    "/partners",
    "/propfirm",
    "sqz"
];

export const navArray = [
    {
        title: "Home",
        path: "/"
    },
    {
        title: "Models",
        path: "#",
        submenu: [
            {
                title: "Stellar",
                path: "/stellar-model"
            },
            {
                title: "Evaluation",
                path: "/evaluation-model"
            },
            {
                title: "Express",
                path: "/express-model"
            }
        ]
    },
    {
        title: "FAQ",
        path: "https://help.fundednext.com/en/"
    },

    {
        title: "Be a Partner",
        path: "#",
        submenu: [
            {
                title: "Affiliate Partner",
                path: "/partners"
            },
            {
                title: "Affiliate Contest",
                path: "/affiliate-competition"
            },
            // {
            //     title: "Payment Partner",
            //     path: "/payment-partner"
            // },
            {
                title: "Partners' FAQ",
                path: "https://help.fundednext.com/en/collections/6096162-affiliate-payment-partner-faq"
            },
            {
                title: "Affiliate Login",
                path: "https://partners.fundednext.com/login/"
            }
        ]
    },
    {
        title: "More",
        path: "#",
        submenu: [
            {
                title: "Symbols",
                path: "/symbols"
            },
            {
                title: "Hall of Fame",
                path: "/hall-of-fame"
            },
            {
                title: "About Us",
                path: "/about-us"
            },
            {
                title: "Blog",
                path: "/blog"
            },
            {
                title: "Competition",
                path: "/competition"
            },
            {
                title: "Infinity Points",
                path: process.env.LOG_IN_URL + "/infinity-points"
            },
            {
                title: "Global Events",
                path: "/events"
            }
        ]
    }
];

export const sliderItems = [
    {
        id: 1,
        title: "85% Lifetime Payout + 140% Reward",
        desc: "$5K to $25K Account",
        code: "PROMISE85"
    },
    {
        id: 2,
        title: "140% Reward",
        desc: "$50K to $200K Account",
        code: "PROMISE140"
    }
];
