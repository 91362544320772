import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import footerData from "../../../data/footer.json";
import {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YoutubeIcon
} from "../../SVGIcons/SVGIcons";
import Copyright from "./Copyright";
import FooterWidget from "./FooterWidget";

function Footer({ footerRef }) {
    const [path, setPath] = useState("");
    const [mobile, setMobile] = useState(false);
    const router = useRouter(null);
    useEffect(() => {
        setPath(router.pathname);
        setMobile(screen.width < 768);
    }, [router.pathname]);

    const {
        contact_widget,
        important_links_widget,
        model_list_widget,
        community_list_widget,
        education_list_widget,
        contact,
        copyright_widget,
        copyright_widget_book
    } = footerData;

    return (
        <div className="footer bg-gray py-12 pb-2 text-white md:py-[51px] lg:w-full">
            <div className="container">
                <div className="mb-4">
                    <Link href="/" className="relative block">
                        <Image
                            alt="footer logo"
                            width={183}
                            height={32}
                            src="https://fundednext.fra1.digitaloceanspaces.com/logo-footer.png"
                        />
                    </Link>
                </div>
                <div className="row">
                    <div className="mt-4 flex w-full flex-wrap md:w-6/12 lg:order-1 lg:w-3/12">
                        <div className="w-6/12 md:w-full">
                            <FooterWidget widget={contact_widget} />
                        </div>

                        <div className="w-6/12  md:w-full">
                            <p className="mb-4 text-center text-base md:text-left md:text-[20px]">
                                Follow Us On
                            </p>
                            <div className="flex flex-wrap  md:justify-start ">
                                <Link
                                    aria-label="facebook link"
                                    href={`${contact.facebook}`}
                                    className="inline-block  w-5/12 pl-10 pr-[16px] md:w-auto md:pl-0"
                                    target="_blank"
                                >
                                    <FacebookIcon />
                                </Link>
                                <Link
                                    aria-label="twitter link"
                                    href={`${contact.twitter}`}
                                    className="inline-block w-5/12 pr-[16px] md:w-auto"
                                    target="_blank"
                                >
                                    <TwitterIcon />
                                </Link>
                                <Link
                                    aria-label="instagram link"
                                    href={`${contact.instagram}`}
                                    className="inline-block w-5/12 pl-10 pr-[16px] pt-[16px] md:w-auto md:pl-0 md:pt-0"
                                    target="_blank"
                                >
                                    <InstagramIcon />
                                </Link>
                                <Link
                                    aria-label="youtube link"
                                    href={`${contact.youtube}`}
                                    className="inline-block w-5/12 pt-[16px] md:w-auto md:pt-0"
                                    target="_blank"
                                >
                                    <YoutubeIcon />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 w-full md:block lg:order-4 lg:w-3/12">
                        <FooterWidget widget={education_list_widget} />
                    </div>

                    <div className="mt-4 w-6/12 lg:order-2 lg:w-3/12">
                        <FooterWidget widget={important_links_widget} />
                    </div>
                    <div className="mt-4 w-6/12 lg:order-3 lg:w-3/12">
                        <FooterWidget widget={model_list_widget} />
                        <FooterWidget
                            mobile={mobile}
                            widget={community_list_widget}
                        />
                    </div>
                </div>

                <Copyright
                    widget={
                        path === "/prop-trader-secrets"
                            ? copyright_widget_book
                            : copyright_widget
                    }
                />
            </div>
        </div>
    );
}

export default Footer;
