import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
    name: "toasts",
    initialState: {
        type: "",
        message: ""
    },
    reducers: {
        openToast: (state, action) => {
            return {
                ...state,
                component: action.payload,
                timeout: 3000
            };
        },
        closeToast: () => {
            return {};
        }
    }
});

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
