import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import useLazyLoad from "../../../../hooks/useLazyLoad";

const footerData = [
    {
        title: "Models",
        links: [
            {
                name: "Express",
                link: "/express-model"
            },
            {
                name: "Evaluation",
                link: "/evaluation-model"
            },
            {
                name: "Stellar",
                link: "/stellar-model"
            },
            {
                name: "Stellar Lite",
                link: "/stellar-model"
            }
        ]
    },
    {
        title: "Education",
        links: [
            {
                name: "Dashboard Tour",
                link: "https://app.fundednext.com/registration"
            },
            {
                name: "Economic Calendar",
                link: "/economic-calendar"
            },
            {
                name: "Trading Firm",
                link: "/proprietary-trading"
            },
            {
                name: "Blog",
                link: "/blog"
            }
        ]
    },
    {
        title: "Community",
        links: [
            {
                name: "Join Local Community",
                link: "https://lnk.bio/fundednext_community"
            },
            {
                name: "Join Discord",
                link: "https://discord.gg/fundednext-945622549373526056"
            },
            {
                name: "Join X",
                link: "https://x.com/FundedNext"
            }
        ]
    },
    {
        title: "Important Links",
        links: [
            {
                name: "FAQ",
                link: "https://help.fundednext.com/en/"
            },
            {
                name: "Affiliate Partner",
                link: "/partners"
            },
            // {
            //     name: "Payment Partner",
            //     link: "/payment-partner"
            // },
            {
                name: "Global Events",
                link: "/events"
            },
            {
                name: "Risk Disclosure",
                link: "/risk-disclosure"
            },
            {
                name: "Server Status",
                link: "https://serverstatus.fundednext.com/"
            }
        ]
    },
    {
        title: "Privacy & Policy",
        links: [
            {
                name: "Terms of Service",
                link: "/terms-of-service"
            },
            {
                name: "Privacy Policy",
                link: "/privacy-policy"
            },
            {
                name: "Cookie Policy",
                link: "/cookie-policy"
            },
            {
                name: "AML Policy",
                link: "/aml-policy"
            },
            {
                name: "Subscribe to newsletter",
                link: "/email-notification"
            }
            // {
            //     name: "Affiliate T&C",
            //     link: "/affiliate-terms"
            // }
        ]
    },
    {
        title: "Contact Us",
        links: [
            {
                name: "Email",
                link: "mailto:support@fundednext.com"
            },
            {
                name: "Live Chat",
                link: "/chat"
            },
            {
                name: "Messenger",
                link: "https://m.me/fundednext"
            },
            {
                name: "Connect with us",
                link: "/contact"
            }
        ]
    }
];
const footerDataTwo = {
    footerLogo:
        "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/logo-white.svg",
    socialIConList: [
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/discord.svg",
            link: "https://discord.com/invite/fundednext-945622549373526056"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/facebook.svg",
            link: "https://www.facebook.com/groups/fundednext"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/instagram_footer.svg",
            link: "https://www.instagram.com/fundednext"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/twitter.svg",
            link: "https://x.com/FundedNext"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/youtube.svg",
            link: "https://www.youtube.com/@FundedNext"
        }
    ],
    mail: "support@fundednext.com"
};

const FooterContent = () => {
    const [isVisible, videoRef] = useLazyLoad();
    const [isExpanded, setIsExpanded] = useState(false);
    const handleReadMoreClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="relative flex min-h-[50vh] flex-col justify-between xl:min-h-[767px]   xl:overflow-hidden 2xl:min-h-[990px]">
            <div className="absolute left-0 right-0  top-[-42px] h-[1px] bg-[#ffffff2e] xl:top-0"></div>
            <div
                className="w-ful absolute bottom-0 left-0 right-0 top-0 h-full "
                style={{
                    background:
                        " linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)"
                }}
            ></div>
            <div className="relative flex w-full flex-wrap justify-center gap-y-[30px]   text-white xl:min-h-[400px]">
                <div className="w-full  p-5 py-[30px]  xl:w-[250px] xl:pb-[220px] 2xl:w-[350px] 2xl:bg-updatedFooterBgOne 2xl:px-[30px]">
                    <div className="flex h-full w-full flex-col items-start gap-y-5  lg:justify-between">
                        {/* <img
                            src={footerDataTwo.footerLogo}
                            alt="footer-logo"
                            className="w-[165px]"
                        /> */}
                        <Link href="/">
                            <Image
                                src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/logo-white.svg"
                                alt="footer-logo"
                                width={165}
                                height={29}
                            />
                        </Link>
                        <div className="">
                            <div
                                className="flex items-center lg:gap-x-1"
                                role="navigation"
                                aria-label="Social Media Links"
                            >
                                {footerDataTwo.socialIConList?.map(
                                    (social, index) => {
                                        return (
                                            <Link
                                                href={social.link}
                                                key={index}
                                            >
                                                <img
                                                    src={social.icon}
                                                    alt="social-icon"
                                                />
                                            </Link>
                                        );
                                    }
                                )}
                            </div>
                            <Link
                                className="text-sm font-[400] text-white"
                                href={`
                                    mailto:${footerDataTwo.mail}
                                `}
                            >
                                {footerDataTwo.mail}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className=" flex w-full flex-wrap xl:w-[calc(100%-250px)] 2xl:w-[calc(100%-350px)]">
                    {footerData?.map((item, index) => {
                        return (
                            <div
                                className={`   ${index % 2 === 1 ? "xl:bg-updatedFooterBgOne" : "xl:bg-updatedFooterBgTwo"} w-1/2 gap-y-[30px] px-5  py-[30px] text-white md:w-4/12 lg:w-2/12 2xl:px-[30px]`}
                                key={index}
                            >
                                <div
                                    className="flex flex-col items-start"
                                    key={index}
                                >
                                    <p className="pb-6 text-sm font-[500] text-[rgba(255,255,255,0.40)]">
                                        {item?.title}
                                    </p>
                                    <div className="flex flex-col gap-y-[12px]">
                                        {item?.links?.map((link, index) => {
                                            return (
                                                <Link
                                                    href={link.link}
                                                    key={index}
                                                    className="text-[12px] font-[500] text-white 2xl:text-sm"
                                                >
                                                    {link.name}
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="relative z-[999] mx-auto px-4 py-10 xl:max-w-[1216px]">
                <div className="mb-10 border-b-[0.5px] border-[#ffffff26]"></div>
                <p className="mb-[60px] text-justify text-xs leading-6 text-[#ffffff66]">
                    {isExpanded ? (
                        <>
                            The website https://fundednext.com is owned and
                            operated by GrowthNext F.Z.C. which is registered in
                            Executive Office No. 7, AI Robotics Hub, C1
                            Building, AFZ, Ajman, UAE, company no.: 28831. The
                            FundedNext-Copyright © 2024 is a registered
                            trademark brand name owned by GrowthNext F.Z.C.
                            Simulated trading operations are done on
                            FundedNext's trading servers operated by the company
                            FundedNext ltd, address : Bonovo Road, Fomboni,
                            Island of Moheli, Comoros Union. Our Cyprus office,
                            Incenteco Trading Limited, is situated at Pittalou
                            26, Laiki Leukothea, 3118, Agia Flakseos, Limassol,
                            Cyprus. Corporate landline number: +35725255160 &
                            Hong Kong office, FundedNext Limited is situated at
                            8/F., China Hong Kong Tower, 8-12 Hennessy Road, Wan
                            Chai, Hong Kong. <br /> <br />
                            FundedNext is dedicated to the educational and
                            evolutionary advancement of traders, offering a
                            state-of-the-art simulation platform for skill
                            assessment. Participants engage in the intensive
                            challenge phases, designed to identify and nurture
                            trading talent. The most adept traders who pass the
                            challenge phases are offered the opportunity to
                            enter into a contract with a separate entity named
                            GrowthNext Champions FZCO (Reg No: 35874), to work
                            as market analyst where they get rewarded up to
                            their achievements. <br /> <br />
                            The information on the Website is provided solely
                            for analysis purposes only and should not be
                            construed as financial, investment, tax, or other
                            advice. Nothing on the Website or in our Services
                            represents a solicitation, advice, endorsement, or
                            offer to purchase or sell stocks or other financial
                            instruments by FundedNext, its agents, employees,
                            contractors, or any connected entities. You are
                            solely responsible for assessing the benefits and
                            risks associated with the use of any information or
                            other content on the Website. All investments
                            include substantial risk, and an individual's
                            investment decisions are solely his/her obligation.
                            All information on the Website is provided “as is”,
                            with no guarantee of completeness, accuracy,
                            timeliness, or of the results obtained from the use
                            of this information, and without warranty of any
                            kind, express or implied. FundedNext, or its
                            partners, employees, or agents, shall in no way be
                            responsible to you or anyone else for any decision
                            made or action taken in reliance on the information
                            on the Website, or for any consequential, special,
                            or similar damages, even if warned of the potential
                            of such damages. Purchases should not be regarded as
                            deposits. All program charges are utilised for
                            working expenses including, however not restricted
                            to, staff, innovation, and other business-related
                            costs.
                        </>
                    ) : (
                        <>
                            The website https://fundednext.com is owned and
                            operated by GrowthNext F.Z.C. which is registered in
                            Executive Office No. 7, AI Robotics Hub, C1
                            Building, AFZ, Ajman, UAE, company no.: 28831. The
                            FundedNext-Copyright © 2024 is a registered
                            trademark brand name owned by GrowthNext F.Z.C.
                            Simulated trading operations are done on
                            FundedNext's trading servers operated by...
                        </>
                    )}{" "}
                    <span
                        className="cursor-pointer text-white underline hover:text-[#ffffff4d]"
                        onClick={handleReadMoreClick}
                    >
                        Read {isExpanded ? "Less" : "More"}
                    </span>
                </p>
                <p className="text-center text-sm text-[#ffffff4d]">
                    FundedNext-Copyright © 2024
                </p>
            </div>
            <div
                className={`absolute bottom-[-150px] left-0  z-[-1] hidden h-[50vh] w-full items-center justify-center xl:flex`}
            >
                {isVisible ? (
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        ref={videoRef}
                        className="relative"
                    >
                        <source src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/Globe%20Animation-updated.mp4" />
                    </video>
                ) : (
                    <div ref={videoRef}></div>
                )}
                {/* <video autoPlay muted loop playsInline className="relative">
                    <source src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/Globe%20Animation.mp4" />
                </video> */}
            </div>
        </div>
    );
};

export default FooterContent;