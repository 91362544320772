import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    singleBlog: null,
    categoryBlog: [],
    blogCategory: [],
    recentBLog: []
};

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
        setSingleBLog: (state, action) => {
            state.singleBlog = action.payload;
        },
        setCategoryBlog: (state, action) => {
            state.categoryBlog = action.payload;
        },
        setAllCategory: (state, action) => {
            state.blogCategory = action.payload;
        },
        setRecentBlog: (state, action) => {
            state.recentBLog = action.payload;
        }
    }
});

export const { setSingleBLog, setCategoryBlog, setAllCategory, setRecentBlog } =
    blogSlice.actions;
export default blogSlice.reducer;
