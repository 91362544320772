import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { deleteCookie } from "../../../helpers";
import { InterFont, Rebond } from "../../../lib/fonts";
import DisclaimerText from "../../common/TopBar/DisclaimerText";
import Footer from "./Footer";
import Header from "./Header";
const Layout = ({ children }) => {
    gsap.registerPlugin(ScrollTrigger);
    const checkPathnameStart = (pathname, prefixes) => {
        return prefixes.some((prefix) => pathname?.startsWith(prefix));
    };
    const hideNavPaths = ["/plan"];
    const hideFooterPaths = ["/plan"];
    const pathName = usePathname();

    const router = useRouter();
    const { cxd, fpr } = router.query;

    const setInCookie = (cookieName, cookieValue, daysToExpire) => {
        const encodedValue = Buffer.from(cookieValue).toString("base64");
        const date = new Date();
        date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000); // Convert days to milliseconds
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${cookieName}=${encodedValue}; ${expires}; domain=.fundednext.com; path=/;`;
    };

    useEffect(() => {
        if (cxd) {
            setInCookie("cxd", cxd, 90);
            deleteCookie("fpr");
        }
        console.log("fpr", fpr);
        if (fpr) {
            setInCookie("fpr", fpr, 90);
            deleteCookie("cxd");
        }
    }, [cxd, fpr]);

    return (
        <>
            {!checkPathnameStart(pathName, hideNavPaths) && (
                <>
                    <Header />
                </>
            )}
            <main
                className={`relative w-screen overflow-hidden bg-black ${Rebond.variable} ${InterFont.className}`}
            >
                {children}
                {!checkPathnameStart(pathName, hideFooterPaths) && <Footer />}
                <div id="updated-modal" />
                <DisclaimerText />
            </main>
        </>
    );
};

export default Layout;
