import React from "react";

const Copyright = ({ widget }) => {
    const year = new Date().getFullYear();
    return (
        <>
            <div className="mb-28 mt-8 sm:mb-44 md:mb-28 md:mt-10 lg:mb-12 xl:mb-20">
                <p className="mb-1 !text-justify text-[8px] text-[#A8A8A8] md:text-center md:text-[12px]">
                The website https://fundednext.com/ is owned and operated by GrowthNext F.Z.C. 
                which is registered in Executive Office No. 7, AI Robotics Hub, 
                C1 Building, AFZ, Ajman, UAE, company no.: 28831. 
                The FundedNext-Copyright © 2024 is a registered 
                trademark brand name owned by GrowthNext F.Z.C. Simulated trading 
                operations are done on FundedNext's trading servers operated by the company 
                FundedNext ltd, address : Bonovo Road, Fomboni, Island of Moheli, Comoros Union. 
                Our Cyprus office, Incenteco Trading Limited, is situated at Pittalou 26, 
                Laiki Leukothea, 3118, Agia Flakseos, Limassol, Cyprus. 
                Corporate landline number: +35725255160 & Hong Kong office, 
                FundedNext Limited is situated at 8/F., China Hong Kong Tower, 
                8-12 Hennessy Road, Wan Chai, Hong Kong.
                </p>
                <p className="mb-1 pt-2 !text-justify text-[8px] text-[#A8A8A8] md:text-center md:text-[12px]">
                FundedNext is dedicated to the educational and evolutionary advancement of traders, 
                offering a state-of-the-art simulation platform for skill assessment. Participants 
                engage in the intensive challenge phases, designed to identify and nurture trading 
                talent. The most adept traders who pass the challenge phases are offered the 
                opportunity to enter into a contract with a separate entity named GrowthNext 
                Champions FZCO (Reg No: 35874), to work as market analyst where they get rewarded 
                up to their achievements.
                </p>

                <p className="pt-2 !text-justify text-[8px] text-[#A8A8A8] md:text-center md:text-[12px]">
                The information on the Website is provided solely for analysis purposes only and 
                should not be construed as financial, investment, tax, or other advice. Nothing 
                on the Website or in our Services represents a solicitation, advice, endorsement, 
                or offer to purchase or sell stocks or other financial instruments by FundedNext, 
                its agents, employees, contractors, or any connected entities. You are solely 
                responsible for assessing the benefits and risks associated with the use of any 
                information or other content on the Website. All investments include substantial 
                risk, and an individual's investment decisions are solely his/her obligation. 
                All information on the Website is provided “as is”, with no guarantee of 
                completeness, accuracy, timeliness, or of the results obtained from the use 
                of this information, and without warranty of any kind, express or implied. 
                FundedNext, or its partners, employees, or agents, shall in no way be responsible 
                to you or anyone else for any decision made or action taken in reliance on the 
                information on the Website, or for any consequential, special, or similar damages, 
                even if warned of the potential of such damages. Purchases should not be regarded 
                as deposits. All program charges are utilised for working expenses including, 
                however not restricted to, staff, innovation, and other business-related costs.
                </p>
            </div>
        </>
    );
};

export default Copyright;
