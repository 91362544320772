export const SOCIAL_MEDIA_DATA = {
    type: "website",
    title: "FundedNext Monthly Competition",
    description:
        "It's more than a competition, it's a chance to refine strategies and connect with fellow traders.",
    image: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/monthly_competition_vs2_1.png",
    siteName: process.env.BASE_PATH,
    imageAlt: "Image Alt",
    url: `${process.env.BASE_PATH}/competition/`
};
