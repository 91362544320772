import Link from "next/link";
import React from "react";
const FooterWidget = ({ widget, mobile }) => {
    const listItems =
        widget?.widget_items_list.map((item, key) => {
            return (
                <li className="my-1" key={key}>
                    <Link
                        href={item.url}
                        target={item.target || "_self"}
                        className="text-[#A8A8A8]"
                    >
                        {mobile ? item.textMobile : item.text}
                    </Link>
                </li>
            );
        }) || "Insert widget list item";
    return (
        <>
            <h3 className="text-base md:text-[20px] font-medium">
                {widget?.widget_title || "Insert Widget Title"}
            </h3>
            <ul className="my-4 text-[14px] md:text-base">{listItems}</ul>
        </>
    );
};

export default FooterWidget;
