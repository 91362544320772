import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCountyLocation = createAsyncThunk(
    "location/getCountry",
    async () => {
        let response = await fetch(`https://geoapi.fundednext.com/api`, {
            headers: {
                Accept: "application/json"
            }
        });

        if (response.ok) {
            let data = await response.json();
            return data;
        } else {
            throw new Error("Network response was not ok.");
        }
    }
);

const locationSlice = createSlice({
    name: "location",
    initialState: {
        countryInfo: null,
        status: "loading", // 'loading', 'succeeded', 'failed'
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountyLocation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCountyLocation.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.countryInfo = action.payload;
                state.error = null;
            })
            .addCase(fetchCountyLocation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    }
});

export default locationSlice.reducer;
