import Link from "next/link";
import React from "react";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import AngleDown from "../../../icon/AngleDown";
import ChevronDown from "../../../icon/ChevronDown";

const ListItem = ({
    title,
    path,
    submenu = null,
    toggleSubmenu,
    activeSubmenu,
    isDarkNav
}) => {
    const handleToggleScroll = () => {
        document.body.style.overflow = "auto";
    };
    const isLg = useMediaQuery("(min-width:1024px)");
    const commonStyles = `block lg:text-sm font-medium py-2 lg:py-8 text-left ${isDarkNav ? "text-white" : "text-black"}`;

    const renderNormalItem = () => {
        return (
            <li>
                <Link
                    href={path}
                    className={commonStyles}
                    onClick={handleToggleScroll}
                >
                    {title}
                </Link>
            </li>
        );
    };

    const submenuItem = () => {
        return submenu?.map((item) => (
            <li key={item.title}>
                <Link
                    onClick={handleToggleScroll}
                    href={item?.path}
                    className={`${isDarkNav ? "text-white hover:bg-black" : "border-black/10 text-black shadow-lg hover:text-purple lg:bg-white"} block px-4 leading-[30px] transition-all duration-300  lg:px-3 lg:py-2 lg:text-sm`}
                >
                    {item?.title}
                </Link>
            </li>
        ));
    };

    const mobileSubmenuStyle = () => {
        if (isLg) return null;

        if (activeSubmenu === title) {
            return "visible max-h-screen py-3 opacity-100";
        } else {
            return "invisible max-h-0 opacity-0";
        }
    };

    const withSubmenu = () => {
        return (
            <li className="group relative">
                <a
                    href="#"
                    className={`flex items-center gap-1 ${commonStyles}`}
                    onClick={() => toggleSubmenu(title)}
                >
                    {title}
                    <span>{isDarkNav ? <AngleDown /> : <ChevronDown />}</span>
                </a>

                <ul
                    className={`v2-submenu left-0 top-full w-full overflow-hidden rounded-2xl bg-[rgba(255,255,255,.05)] text-left transition-all duration-300 lg:invisible lg:absolute lg:h-auto lg:min-w-[200px] lg:rounded lg:bg-gray lg:opacity-0 lg:group-hover:visible lg:group-hover:opacity-100 ${mobileSubmenuStyle()}`}
                >
                    {submenuItem()}
                </ul>
            </li>
        );
    };

    const renderList = () => {
        if (!submenu) return renderNormalItem();
        return withSubmenu();
    };

    return renderList();
};

export default ListItem;
