import React, { useEffect, useState } from "react";
import { CopyIconSlider, TickMarkSlider } from "../../../SVGIcons/SVGIcons";
import { handleCopyClick } from "../../../Sections/blog/utils";
import { sliderItems } from "./nav.data";

export default function MarqueeSlider() {
    const [copied, setCopied] = useState("");
    const [copyIndex, setCopyIndex] = useState(null);

    useEffect(() => {
        if (copied) {
            const timeoutId = setTimeout(() => {
                setCopied("");
            }, 1500);
            return () => clearTimeout(timeoutId);
        }
    }, [copied]);

    const MarqueeItems = () => (
        <>
            {[...Array(50)]
                .flatMap(() => sliderItems)
                .map((item, index) => (
                    <div
                        className="mx-6 inline-flex items-center justify-between gap-x-3 text-white lg:mx-6"
                        key={index}
                    >
                        <div>
                            <img
                                width={40}
                                src={`https://fundednext.fra1.cdn.digitaloceanspaces.com/slider_top_${item.id}.png`}
                                alt=""
                            />
                        </div>
                        <div>
                            <h4 className="pb-0.5 text-[15px] font-medium">
                                {item.title}
                            </h4>
                            <div className="flex items-center justify-between gap-x-3 sm:pt-[1px]">
                                <h6 className="text-sm font-extralight">
                                    {item.desc}
                                </h6>
                                <button
                                    onClick={() => {
                                        setCopyIndex(index);
                                        setCopied(handleCopyClick(item.code));
                                    }}
                                    title="Copy the code"
                                    className={`flex items-center justify-center gap-x-1 rounded border px-1.5 text-[12px] font-medium hover:bg-black ${item.id === 2 ? "border-[#A276FF] text-[#A276FF]" : "border-[#2F9BE9] text-[#2F9BE9]"}`}
                                >
                                    CODE : {item.code}
                                    {copied === item.code &&
                                    copyIndex === index ? (
                                        <TickMarkSlider />
                                    ) : (
                                        <CopyIconSlider />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
    return (
        <div className="relative flex overflow-x-hidden border-b border-[rgba(255,255,255,0.08)] bg-[#0D134D]">
            <div
                style={{
                    background:
                        "linear-gradient(270deg, rgba(23, 30, 98, 0.00) 0%, #0D134D 90%)"
                }}
                className="absolute left-0 z-10 hidden h-full w-10 sm:block sm:w-[6rem] xl:w-[10rem] 2xl:w-[12rem]"
            ></div>

            <div className="offer-marquee whitespace-nowrap pb-2 pt-2.5">
                <MarqueeItems />
            </div>
            <div className="offer-marquee2 absolute top-0 whitespace-nowrap pb-2 pt-2.5">
                <MarqueeItems />
            </div>

            <div
                style={{
                    background:
                        "linear-gradient(90deg, rgba(23, 30, 98, 0.00) 0%, #0D134D 90%)"
                }}
                className="absolute right-0 hidden h-full w-10  sm:block sm:w-[6rem] xl:w-[10rem] 2xl:w-[12rem]"
            ></div>
        </div>
    );
}
