export const getUserFingerPrint = () => {
    if (typeof window === "undefined") return null;
    try {
        // User Agent String
        const userAgent = navigator.userAgent || "unknown";
        // Screen Resolution
        const screenWidth = window.screen.width || "unknown";
        const screenHeight = window.screen.height || "unknown";

        // Time Zone
        const timeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone || "unknown";
        // Language
        const userLanguage =
            navigator.language || navigator.userLanguage || "unknown";
        // Browser Cookies
        const cookiesEnabled = navigator.cookieEnabled;

        const platform = navigator.platform || "unknown";
        const cpuClass = navigator.cpuClass || "unknown";
        const oscpu = navigator.oscpu || "unknown";

        // Device Pixel Ratio
        const devicePixelRatio = window.devicePixelRatio || 1;

        // Touchscreen Support
        const isTouchscreen =
            "ontouchstart" in window ||
            navigator.maxTouchPoints > 0 ||
            "unknown";

        return {
            userAgent,
            screenWidth,
            screenHeight,
            timeZone,
            userLanguage,
            cookiesEnabled,
            platform,
            cpuClass,
            oscpu,
            devicePixelRatio,
            isTouchscreen,
            screenSize: `${screenWidth}X${screenHeight}`
        };
    } catch (error) {
        return null;
    }
};

export function simpleHash(input) {
    let hash = 0;
    if (input.length === 0) return hash;

    for (let i = 0; i < input.length; i++) {
        const char = input.charCodeAt(i);
        hash = (hash << 5) - hash + char;
    }

    return hash.toString();
}
export function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomValue = Math.random().toString(36).substring(2, 15);
    const uniqueId = timestamp.toString(36) + randomValue;
    return uniqueId;
}

export function generateDeviceId() {
    if (typeof window === "undefined") return null;
    try {
        if (localStorage.getItem("__fn-deviceId")) {
            return localStorage.getItem("__fn-deviceId");
        } else {
            // Generate a random ID
            const randomId = Math.random().toString(36).substring(2, 15);

            // Create a unique identifier based on browser features
            const browserFeatures = {
                userAgent: navigator.userAgent,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height
            };

            // Convert the browser features to a string
            const browserFeaturesString = JSON.stringify(browserFeatures);

            // Combine the random ID and browser features
            const deviceId = randomId + browserFeaturesString;

            // Hash the device ID using the simpleHash function
            const hashedDeviceId =
                simpleHash(deviceId) + "-" + generateUniqueId();

            // Store the generated and hashed device ID in localStorage
            localStorage.setItem("__fn-deviceId", hashedDeviceId);

            return hashedDeviceId;
        }
    } catch (error) {
        console.error("Error generating device ID:", error);
        return null;
    }
}
