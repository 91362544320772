import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    offerInfo: {}
};

const commonSlice = createSlice({
    name: "offer",
    initialState,
    reducers: {
        setOfferInfo: (state, action) => {
            state.offerInfo = action.payload;
        }
    }
});

export const { setOfferInfo } = commonSlice.actions;
export default commonSlice.reducer;
