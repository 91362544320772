import { useEffect, useRef, useState } from "react";

const useScrollDirection = (threshold = 0) => {
    const [scrollDirection, setScrollDirection] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const lastScrollY = useRef(0);
    const ticking = useRef(false);

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }

        lastScrollY.current = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY.current) < threshold) {
                ticking.current = false;
                return;
            }

            setScrollDirection(scrollY > lastScrollY.current ? "down" : "up");
            setScrollTop(scrollY);
            lastScrollY.current = scrollY > 0 ? scrollY : 0;
            ticking.current = false;
        };

        const onScroll = () => {
            if (!ticking.current) {
                window.requestAnimationFrame(updateScrollDirection);
                ticking.current = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [threshold]);

    return { scrollDirection, scrollTop };
};

export default useScrollDirection;
