import { createSlice } from "@reduxjs/toolkit";
import {
    ACCOUNT_TYPES,
    PACKAGE_NAMES,
    SERVER_TYPES
} from "../../constants/globalConstants";
const initialState = {
    selectedPlanModel: PACKAGE_NAMES.STELLAR_STEP_TWO,
    euroRate: 0,
    activePlans: {},
    serverType: SERVER_TYPES.MT5,
    accountType: ACCOUNT_TYPES.SWAP,
    brokerType: 2
};

const commonSlice = createSlice({
    name: "pricing",
    initialState,
    reducers: {
        setSelectedPlanModel: (state, action) => {
            state.selectedPlanModel = action.payload;
        },
        setActivePlans: (state, action) => {
            state.activePlans = action.payload;
        },
        setEuroRate: (state, action) => {
            state.euroRate = action.payload;
        },
        setServerType: (state, action) => {
            state.serverType = action.payload;
        },
        setAccountType: (state, action) => {
            state.accountType = action.payload;
        },
        setBrokerType: (state, action) => {
            state.brokerType = action.payload;
        }
    }
});

export const {
    setSelectedPlanModel,
    setActivePlans,
    setEuroRate,
    setServerType,
    setAccountType,
    setBrokerType
} = commonSlice.actions;
export default commonSlice.reducer;
