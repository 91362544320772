"use client";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function DisclaimerText() {
    const [disclaimerClosed, setDisclaimerClosed] = useState(false);
    const isLg = useMediaQuery("(min-width:1024px)");

    const removeDisclaimerText = () => {
        setDisclaimerClosed(false);
        sessionStorage.setItem("disclaimerClosed", true);
    };

    useEffect(() => {
        const isExist = sessionStorage.getItem("disclaimerClosed");
        setDisclaimerClosed(Boolean(isExist) ? false : true);

        const styleTag = document.createElement("style");
        styleTag.innerHTML = `
                @media screen and (min-width: 1024px) {
                    #symbol-table-header {
                        ${Boolean(isExist) && "top: 3.7rem !important"};
                    }
                }

                @media screen and (min-width: 1280px) {
                    #symbol-table-header {
                        ${Boolean(isExist) && "top: 4.65rem !important"};
                    }
                }

                @media screen and (max-width: 7680px) {
                    #fundednext-blog-hero {
                        ${
                            Boolean(isExist)
                                ? "padding-top: 65px !important;"
                                : "padding-top: 130px !important;"
                        }
                    }
                }                

                #events-hero-nav {
                    margin-top: ${
                        Boolean(isExist)
                            ? "-1rem !important"
                            : "3.35rem !important"
                    };

                @media screen and (max-width: 640px) {
                    #disclaimer-text {
                        ${Boolean(isExist) && "padding-top: 0px !important"};
                    }
                    .disclaimer-text {
                        ${Boolean(isExist) && "padding-top: 5px !important"};
                    }
                }  

            `;

        document.head.appendChild(styleTag);
        return () => document.head.removeChild(styleTag);
    }, [disclaimerClosed]);

    useEffect(() => {
        const handleResize = () => {
            const weglot = document.querySelector(
                ".weglot-container .country-selector"
            );
            const mainNavBar = document.querySelector(".mainNavBar ");
            const pricingTableHeading = document.querySelector(
                ".pricing-table-heading "
            );

            const headerHeight =
                document.getElementsByTagName("header")[0]?.offsetHeight;
            let calcH =
                headerHeight -
                mainNavBar?.offsetHeight +
                mainNavBar?.offsetHeight / 1.5;

            if (isLg) {
                calcH =
                    headerHeight -
                    mainNavBar?.offsetHeight +
                    mainNavBar?.offsetHeight / 2;
            }

            if (pricingTableHeading) {
                pricingTableHeading.style.top = `${headerHeight}px`;
            }

            if (weglot) {
                weglot.style.top = `${calcH}px`;
                weglot.style.transform = "translateY(-50%)";
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [disclaimerClosed, isLg]);

    return (
        disclaimerClosed && (
            <div className="disclaimer fixed bottom-0 left-0 z-[9999] flex w-full items-center justify-center bg-black">
                <p className="px-5 py-2.5 text-[11px] font-extralight leading-4 text-white sm:px-5 sm:text-xs sm:font-light sm:leading-5">
                    Clients are provided with demo accounts that contain
                    simulated funds for trading activities. Please note that all
                    client trading operations are conducted in a simulated
                    environment. More details can be found in the{" "}
                    <Link
                        target="_blank"
                        href="https://help.fundednext.com/"
                        className="underline"
                    >
                        FAQ section.
                    </Link>
                </p>

                <button
                    onClick={removeDisclaimerText}
                    className="absolute bottom-0 right-0 mb-1 mr-1 sm:mb-2 sm:mr-3"
                    aria-label="Remove disclaimer text"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 w-[26px] sm:w-[w-27px]"
                        viewBox="0 0 30 30"
                        fill="none"
                    >
                        <path
                            d="M15 2.5C12.5277 2.5 10.111 3.23311 8.05538 4.60663C5.99976 5.98015 4.39761 7.93238 3.45151 10.2165C2.50542 12.5005 2.25787 15.0139 2.74019 17.4386C3.2225 19.8634 4.41301 22.0907 6.16117 23.8388C7.90933 25.587 10.1366 26.7775 12.5614 27.2598C14.9861 27.7421 17.4995 27.4946 19.7836 26.5485C22.0676 25.6024 24.0199 24.0002 25.3934 21.9446C26.7669 19.889 27.5 17.4723 27.5 15C27.5 13.3585 27.1767 11.733 26.5485 10.2165C25.9203 8.69989 24.9996 7.3219 23.8388 6.16117C22.6781 5.00043 21.3001 4.07969 19.7836 3.45151C18.267 2.82332 16.6415 2.5 15 2.5ZM15 25C13.0222 25 11.0888 24.4135 9.4443 23.3147C7.79981 22.2159 6.51809 20.6541 5.76121 18.8268C5.00433 16.9996 4.8063 14.9889 5.19215 13.0491C5.57801 11.1093 6.53041 9.32746 7.92894 7.92893C9.32746 6.53041 11.1093 5.578 13.0491 5.19215C14.9889 4.8063 16.9996 5.00433 18.8268 5.76121C20.6541 6.51808 22.2159 7.79981 23.3147 9.4443C24.4135 11.0888 25 13.0222 25 15C25 17.6522 23.9464 20.1957 22.0711 22.0711C20.1957 23.9464 17.6522 25 15 25Z"
                            fill="white"
                        />
                        <path
                            d="M18.3869 11.6119C18.2707 11.4948 18.1325 11.4018 17.9802 11.3383C17.8278 11.2749 17.6645 11.2422 17.4994 11.2422C17.3344 11.2422 17.171 11.2749 17.0187 11.3383C16.8664 11.4018 16.7281 11.4948 16.6119 11.6119L14.9994 13.2369L13.3869 11.6119C13.1516 11.3766 12.8323 11.2443 12.4994 11.2443C12.1666 11.2443 11.8473 11.3766 11.6119 11.6119C11.3766 11.8473 11.2443 12.1666 11.2443 12.4994C11.2443 12.8323 11.3766 13.1516 11.6119 13.3869L13.2369 14.9994L11.6119 16.6119C11.4948 16.7281 11.4018 16.8664 11.3383 17.0187C11.2749 17.171 11.2422 17.3344 11.2422 17.4994C11.2422 17.6645 11.2749 17.8278 11.3383 17.9802C11.4018 18.1325 11.4948 18.2707 11.6119 18.3869C11.7281 18.5041 11.8664 18.5971 12.0187 18.6605C12.171 18.724 12.3344 18.7567 12.4994 18.7567C12.6645 18.7567 12.8278 18.724 12.9802 18.6605C13.1325 18.5971 13.2707 18.5041 13.3869 18.3869L14.9994 16.7619L16.6119 18.3869C16.7281 18.5041 16.8664 18.5971 17.0187 18.6605C17.171 18.724 17.3344 18.7567 17.4994 18.7567C17.6645 18.7567 17.8278 18.724 17.9802 18.6605C18.1325 18.5971 18.2707 18.5041 18.3869 18.3869C18.5041 18.2707 18.5971 18.1325 18.6605 17.9802C18.724 17.8278 18.7567 17.6645 18.7567 17.4994C18.7567 17.3344 18.724 17.171 18.6605 17.0187C18.5971 16.8664 18.5041 16.7281 18.3869 16.6119L16.7619 14.9994L18.3869 13.3869C18.5041 13.2707 18.5971 13.1325 18.6605 12.9802C18.724 12.8278 18.7567 12.6645 18.7567 12.4994C18.7567 12.3344 18.724 12.171 18.6605 12.0187C18.5971 11.8664 18.5041 11.7281 18.3869 11.6119Z"
                            fill="white"
                        />
                    </svg>
                </button>
            </div>
        )
    );
}
