import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import abTestingSlice from "./slices/abTestingSlice";
import blogSlice from "./slices/blogSlice";
import clendarReducer from "./slices/calendarSlice";
import choosePlanSlice from "./slices/choosePlanSlice";
import locationReducer from "./slices/locationSlice";
import offerSlice from "./slices/offerSlice";
import pricingSlice from "./slices/pricingSlice";
import toastReducer from "./slices/toastReducer";
export const store = configureStore({
    reducer: {
        clendar: clendarReducer,
        pricing: pricingSlice,
        offer: offerSlice,
        blog: blogSlice,
        location: locationReducer,
        toast: toastReducer,
        choosePlan: choosePlanSlice,
        abTesting: abTestingSlice
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
});
