import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    startChallengeCTA: "control"
};

const abTestingSlice = createSlice({
    name: "posthog",
    initialState,
    reducers: {
        setStartChallengeCTA: (state, action) => {
            state.startChallengeCTA = action.payload;
        }
    }
});

export const { setStartChallengeCTA } = abTestingSlice.actions;
export default abTestingSlice.reducer;
