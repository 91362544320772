import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentPage: 1,
    allData: [],
    filterList: [],
    impacts: {
        Low: false,
        Medium: false,
        High: false,
        Holiday: false
    },
    restrictions: {
        Restrictions: false,
        "No Restrictions": false
    },
    days: {
        Sunday: false,
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false
    },
    countrys: {
        ALL: false,
        NZD: false,
        AUD: false,
        CAD: false,
        EUR: false,
        GBP: false,
        USD: false,
        CHF: false,
        JPY: false,
        CNY: false
    },
    isLoading: false,
    isMblFilterOpen: false,
    selectedFilters: {
        country: 0,
        day: 0,
        impact: 0,
        restriction: 0
    }
};

export const fetchCalendarData = createAsyncThunk(
    "fetchCalendarData",
    async (timeZone, { getState }) => {
        const state = getState().calendar; // get the current state of the "calendar" slice
        let url;
        if (timeZone) {
            url = `${process.env.CALENDAR_BASE_URL}/news-calendar`;
        }
        let res = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                timezone: timeZone
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${btoa(
                    `${process.env.API_USER.trim()}:${process.env.API_PASSWORD.trim()}`
                )}`
            }
        });
        const data = await res.json();
        const updatedState = {
            ...initialState,
            allData: data.getLastWeekData,
            filterList: data.getLastWeekData
        };
        return updatedState;
    }
);

const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {
        updatePageNumber: (state, action) => {
            state.currentPage = action.payload;
        },
        filterByCountry: (state, action) => {
            state.countrys = action.payload;
            const totalCountry = Object.values(state.countrys).filter(
                (item) => item === true
            ).length;
            state.selectedFilters.country = totalCountry;
        },
        filterByDays: (state, action) => {
            state.days = action.payload;
            const totalDay = Object.values(state.days).filter(
                (item) => item === true
            ).length;
            state.selectedFilters.day = totalDay;
        },
        filterByImpact: (state, action) => {
            state.impacts = action.payload;
            const totalImpact = Object.values(state.impacts).filter(
                (item) => item === true
            ).length;
            state.selectedFilters.impact = totalImpact;
        },
        filterByRestriction: (state, action) => {
            state.restrictions = action.payload;
            const totalRestriction = Object.values(state.restrictions).filter(
                (item) => item === true
            ).length;
            state.selectedFilters.restriction = totalRestriction;
        },
        updateFilterData: (state, action) => {
            state.filterList = action.payload;
        },
        clearAllFilter: (state, action) => {
            state.impacts = {
                Low: false,
                Medium: false,
                High: false,
                Holiday: false
            };
            state.days = {
                Sunday: false,
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false
            };
            state.countrys = {
                NZD: false,
                AUD: false,
                CAD: false,
                EUR: false,
                GBP: false,
                USD: false,
                CHF: false,
                JPY: false,
                CNY: false
            };
            state.restrictions = {
                Restrictions: false,
                "No Restrictions": false
            };

            state.selectedFilters = {
                country: 0,
                day: 0,
                impact: 0,
                restriction: 0
            };
        },
        handleMblFilter: (state, action) => {
            state.isMblFilterOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCalendarData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCalendarData.fulfilled, (state, action) => {
                state.isLoading = false;
                Object.assign(state, action.payload);
            })
            .addCase(fetchCalendarData.rejected, (state) => {
                state.isLoading = false;
            });
    }
});

export const {
    filterByCountry,
    filterByImpact,
    filterByDays,
    filterByRestriction,
    updatePageNumber,
    updateFilterData,
    handleMblFilter,
    clearAllFilter
} = calendarSlice.actions;

export default calendarSlice.reducer;
