export default function AngleDown(props) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                {...props}
            >
                <path
                    d="M1 1.50195L6 6.50195L11 1.50195"
                    stroke="white"
                    strokeWidth="1.8"
                />
            </svg>
        </>
    );
}
