// Everything in this page are developed by Raw HTML5 Canvas API.

// Functionalites
// 1. Moving Stars which are rotating around the center of the screen.
// 2. Meteors are moving from top to bottom of the screen.

import { useEffect, useRef } from "react";

const StarCanvas = () => {
    const canvasRef = useRef(null);
    useEffect(() => {
        // Get the canvas from DOM
        const canvas = canvasRef.current;

        // Get the 2D context from the canvas. We will draw everything on this context.
        const ctx = canvas.getContext("2d");

        // _2PI is used to calculate the angle in radians.
        const _2PI = Math.PI * 2;

        // Set the canvas width and height to the window width and height.
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        // This colors is used to draw the stars in the canvas.
        const colors = ["#4C43FF", "#00D1FF", "#BB76FF", "#ffffff"];

        // Stars class is used to draw and update the stars in the canvas.
        class Stars {
            constructor() {
                this.x = Math.random() * canvas.width;
                this.y = getRandomValue(canvas.height / 2, canvas.height);
                this.r = getRandomValue(0.1, 0.7);
                this.color = colors[Math.floor(Math.random() * colors.length)];
                this.ySpeed = getRandomValue(0.1, 0.5);
            }
            draw() {
                ctx.beginPath();
                ctx.fillStyle = this.color;
                ctx.arc(this.x, this.y, this.r, 0, _2PI);
                ctx.fill();
                ctx.closePath();
            }
            update() {
                this.draw();

                this.y -= this.ySpeed;
                if (this.y < 0) {
                    this.y = Math.random() * canvas.height;
                }
            }
        }

        // Meteor class is used to draw and update the meteors in the canvas.
        class Meteor {
            constructor() {
                this.reset();
            }
            reset() {
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * canvas.height;
                this.moveX = this.x + 60;
                this.moveY = this.y + 60;
                this.originX = this.x;
                this.originY = this.y;
                this.size = 1;
                this.speed = 1;
            }
            draw() {
                ctx.save();
                const grad = ctx.createLinearGradient(
                    this.x,
                    this.y,
                    this.moveX,
                    this.moveY
                );
                grad.addColorStop(0, "rgba(255, 255, 255, 0)");
                grad.addColorStop(1, "white");

                ctx.strokeStyle = grad;
                ctx.lineCap = "round";
                ctx.shadowColor = "rgba(255, 255, 255, 1)";
                ctx.shadowBlur = 30;

                ctx.beginPath();
                ctx.moveTo(this.x, this.y);
                ctx.lineWidth = this.size;
                ctx.lineTo(this.moveX, this.moveY);
                ctx.stroke();
                ctx.closePath();
                ctx.restore();
            }
            update() {
                if (this.moveY < this.originY + 300) {
                    this.x += this.speed + 0.2;
                    this.y += this.speed + 0.2;
                    this.moveX += this.speed;
                    this.moveY += this.speed;
                } else if (this.moveY >= this.originY + 300) {
                    this.x += this.speed + 0.2;
                    this.y += this.speed + 0.2;
                }

                if (this.y >= this.originY + 300) {
                    this.reset();
                }
            }
        }

        // Create the meteors.
        const meteor = new Meteor();

        const getRandomValue = (min, max) => {
            return min + (max - min) * Math.random();
        };

        // Create the stars.
        const stars = [];
        const starCount = 200;
        for (let i = 0; i < starCount; i++) {
            const star = new Stars();
            stars.push(star);
        }

        // This function is used to animate the stars and meteors in the canvas.
        function animateStars() {
            // clear the canvas after every frame
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            stars.forEach((star) => {
                star.update();
            });
            meteor.update();
            meteor.draw();
            requestAnimationFrame(animateStars);
        }

        // Start the animation.
        animateStars();
    }, []);

    return <canvas ref={canvasRef} />;
};

export default StarCanvas;
