export const dateFormatterForBlog = (inputDate) => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric"
    });
};

export const wordLimiterForBlog = (content, limit) => {
    return content?.split(" ")?.slice(0, limit)?.join(" ") + "...";
};


export const handleCopyClick = (coupon) => {
    const textField = document.createElement("textarea");
    textField.innerText = coupon;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    return coupon
};
