import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    planName: "stellar"
};

const choosePlanSlice = createSlice({
    name: "choosePlan",
    initialState,
    reducers: {
        setPlanName: (state, action) => {
            state.planName = action.payload;
        }
    }
});

export const { setPlanName } = choosePlanSlice.actions;
export default choosePlanSlice.reducer;
