// import Image from 'next/legacy/image'
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import useScrollDirection from "../../../hooks/useScrollDirection";
import MarqueeSlider from "./MarqueeSlider";
import { darkNavPaths, navArray } from "./nav.data";
import ListItem from "./ui/ListItem";
import NavToggler from "./ui/NavToggler";

const LOG_IN_URL = process.env.LOG_IN_URL || "https://app.fundednext.com";
export const IS_MARQUEE_SLIDER_ACTIVE = false;

const Header = () => {
    const pathname = usePathname();
    const isDarkNav = darkNavPaths.some((path) => pathname?.endsWith(path));
    const { scrollDirection, scrollTop } = useScrollDirection(50);
    const isLg = useMediaQuery("(min-width:1024px)");
    const [activeItem, setActiveItem] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleSubmenu = (title) => {
        if (activeItem == title) return setActiveItem(null);
        setActiveItem(title);
    };
    const handleNavToggle = () => {
        if (isOpen) {
            document.body.style.overflow = "auto";
            setIsOpen(false);
        } else {
            document.body.style.overflow = "hidden";
            setIsOpen(true);
        }
    };
    const renderNavList = () => {
        return navArray.map((item, idx) => (
            <ListItem
                isDarkNav={isDarkNav}
                {...item}
                toggleSubmenu={toggleSubmenu}
                activeSubmenu={activeItem}
                handleNavToggle={handleNavToggle}
                key={idx}
            />
        ));
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const languageBtnPosition = document.getElementById(
                "language-switcher-btn"
            );
            const weglotBtnDiv = document.querySelector(
                ".weglot-container.wg-default"
            );

            if (weglotBtnDiv) {
                clearInterval(intervalId);
                weglotBtnDiv.remove();

                if (languageBtnPosition) {
                    languageBtnPosition.appendChild(weglotBtnDiv);
                }
            }
        }, 300);

        return () => clearInterval(intervalId);
    }, [pathname]);

    const renderLogo = () => {
        return (
            <Link
                href="/"
                className="inline-block leading-none"
                area-label="fundednext logo"
            >
                <Image
                    src={`https://fundednext.fra1.cdn.digitaloceanspaces.com${isDarkNav ? "/lander-v2/logo-white.svg" : "/Logo_Main.svg"}`}
                    width={167}
                    height={29}
                    alt="fundednext"
                />
            </Link>
        );
    };

    const renderNavButtons = () => (
        <div className="mb-6 flex flex-wrap items-center justify-start gap-4 sm:justify-center lg:mb-0 lg:h-full lg:justify-end">
            <Link
                href={`${LOG_IN_URL}/login`}
                className={`w-[92px] rounded-[30px] text-center text-[13px] font-medium leading-[32px]  ${isDarkNav ? "bg-white text-black" : "bg-black text-white"}`}
            >
                Log In
            </Link>
            <Link
                href={`${LOG_IN_URL}/subscribe/1?account=swap&account-type=free-trial`}
                className={`w-[92px] rounded-[30px] border bg-transparent text-center text-[13px] font-medium leading-[30px]  ${isDarkNav ? "border-white text-white" : "border-black text-black"}`}
            >
                Free Trial
            </Link>
            <div id="language-switcher-btn"></div>
        </div>
    );

    const mobileNavStyles = !isLg
        ? `fixed top-[90px] bottom-3 overflow-y-auto ${isDarkNav ? "bg-[rgba(255,255,255,0.15)]" : "bg-[rgba(0,0,0,.2)]"} transition-all duration-300 rounded-[20px] right-1/2 md:w-[700px] md:px-0 lg:w-[920px] xl:w-[1140px] 2xl:w-[1220px] w-[calc(100%-32px)] pt-10 !px-8 backdrop-blur-xl h-[calc(100vh-105px)] ${
              isOpen ? "translate-x-1/2" : "translate-x-[200%]"
          }`
        : "";

    const handleStickyNav = () => {
        if (!isLg || scrollTop <= 60) {
            return "border-transparent";
        }
        const backgroundColor = isDarkNav
            ? "bg-[rgba(3,0,20,0.08)]"
            : "bg-[rgba(255,255,255,1)]";

        return `${backgroundColor} backdrop-blur-lg border-[#ffffff0f]`;
    };

    return (
        <header
            id="main-header"
            className={`fixed left-0 right-0 top-0 z-[9999] border-b-[0.4px] transition-all duration-300 ${scrollDirection === "down" ? "-translate-y-full" : ""} ${handleStickyNav()}`}
        >
            {IS_MARQUEE_SLIDER_ACTIVE && <MarqueeSlider />}

            <div className="v2-container mainNavBar mt-4 lg:mt-0">
                <div className="row relative items-center p-3 lg:p-0">
                    <div
                        className={`${isDarkNav ? "bg-[rgba(255,255,255,0.15)] lg:bg-transparent" : "bg-[rgba(0,0,0,.2)] lg:bg-transparent"} absolute left-0  top-0 h-full w-full rounded-[20px] p-3 backdrop-blur-xl lg:hidden lg:rounded-none lg:p-0 lg:backdrop-blur-0`}
                    ></div>
                    <div className="relative flex w-5/12 items-center md:w-3/12 lg:w-2/12">
                        {renderLogo()}
                    </div>
                    <div className="relative w-7/12 text-right md:w-9/12 lg:w-[55%] xl:w-[57%] 2xl:w-7/12">
                        <nav className={mobileNavStyles}>
                            {!isLg && renderNavButtons()}
                            <ul className="w-full items-center justify-end gap-7 lg:flex xl:gap-8">
                                {renderNavList()}
                            </ul>
                        </nav>

                        {!isLg && (
                            <NavToggler
                                callback={handleNavToggle}
                                isOpen={isOpen}
                                isDark={isDarkNav}
                            />
                        )}
                    </div>

                    {isLg && <div className="w-3/12">{renderNavButtons()}</div>}
                </div>
            </div>
        </header>
    );
};

export default Header;
