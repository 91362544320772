import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import { Inter, Poppins, Rubik } from "next/font/google";
import { useDispatch, useSelector } from "react-redux";
import useCountdownBogo from "../../hooks/useCountdownBogo";
// import { PoppinsFont, RubikFont } from "../../lib/fonts";
import { fetchCountyLocation } from "../../redux/slices/locationSlice";
import { generateDeviceId } from "../utils/GetDataFromCookie";
import ToastContainer from "../utils/ToastContainer";
import Header from "../v2/Layout/Header";
import Footer from "./Footer/index";
import { allCountries } from "./offers-countries.data";

export const InterFont = Inter({
    subsets: ["latin"],
    display: "swap",
    weight: ["400", "700"], // Specify the weights you need for Inter
    variable: "--font-inter"
});

export const PoppinsFont = Poppins({
    subsets: ["latin"],
    display: "swap",
    weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
    variable: "--font-poppins" // Specify the weights you need for Poppins
});

export const RubikFont = Rubik({
    subsets: ["latin"],
    display: "swap",
    variable: "--font-rubik"
});

const LegacyLayout = ({ children }) => {
    const [isDomLoad, setIsDomLoad] = useState(false);
    const router = useRouter();
    const dispatch = useDispatch();

    const hideMenubarPaths = ["/prop-trader-secrets", "/404"];
    const isAffiliateCompetition = router.pathname === "/affiliate-competition";

    const targetDate = new Date("2024-06-13T23:59:00").getTime();
    const { countryInfo } = useSelector((state) => state.location);

    const { hours, minutes, seconds } = useCountdownBogo(targetDate);
    const isOfferForAfrica = ["USA"].includes(countryInfo?.country);

    useEffect(() => {
        setIsDomLoad(true);
        generateDeviceId();
    }, []);

    useEffect(() => {
        dispatch(fetchCountyLocation());
    }, [dispatch]);

    const renderOffers = useCallback((offers) => {
        return offers.map((item) => (
            <img
                key={item.id}
                src={`https://fundednext.fra1.cdn.digitaloceanspaces.com/offer-banner/${item.url}`}
                alt="Offer banner"
                className={item.styles}
            />
        ));
    }, []);

    const timerCardStyles = {
        borderRadius: "9.076px",
        border: "1.135px solid #E8E6FB",
        background: "#1D0930",
        boxShadow: "0px 3.33px 11.655px 0px rgba(217, 225, 255, 0.47)"
    };

    const IS_OFFER_ACTIVE = false;

    const isNotFound = router.pathname !== "/404";
    const isPlanPage = router.pathname === "/plan";
    const { cxd } = router.query;

    const setCxdInCookie = (cookieName, cookieValue) => {
        const encodedValue = Buffer.from(cookieValue).toString("base64");
        document.cookie = `${cookieName}=${encodedValue}; domain=.fundednext.com;`;
    };

    useEffect(() => {
        if (cxd) {
            setCxdInCookie("cxd", cxd);
        }
    }, [cxd]);

    const IS_TIMER = true;
    return (
        <>
            <ToastContainer />
            <div className="relative">
                <main
                    className={`  ${PoppinsFont.variable} ${RubikFont.variable} ${InterFont.className}`}
                >
                    {!hideMenubarPaths.includes(router.pathname) && <Header />}
                    {children}
                </main>
                {isNotFound && (
                    <footer>
                        <Footer />
                    </footer>
                )}
            </div>
            {IS_OFFER_ACTIVE && (
                <div target="_blank" className="relative z-[99]">
                    {renderOffers(allCountries)}
                    {isDomLoad && (
                        <div className="fixed bottom-3 z-[99999] ml-[21%] w-full sm:bottom-10 sm:ml-[30%] md:bottom-9 md:ml-[22%] lg:bottom-0.5 lg:-ml-24 lg:flex lg:items-end lg:justify-end xl:bottom-2 xl:-ml-[12%] 2xl:bottom-6 2xl:-ml-[20%]">
                            {IS_TIMER ? (
                                <>
                                    <h5 className="mb-1.5 text-center text-[12px] font-semibold text-white sm:mb-3 sm:text-sm lg:mr-6 lg:font-medium xl:mr-4 xl:text-base">
                                        Offer Ends In
                                    </h5>
                                    <div className="flex items-center justify-center gap-x-1 sm:gap-x-2">
                                        <div
                                            style={timerCardStyles}
                                            className="px-2.5 py-1 text-center sm:px-4"
                                        >
                                            <h4 className="font-Rubik text-[11px] font-bold text-white sm:text-sm">
                                                {hours}
                                            </h4>
                                            <h5 className="-mt-1 font-Rubik text-[10px] font-semibold text-[#A9A6E3] sm:mt-0 sm:text-xs">
                                                Hr
                                            </h5>
                                        </div>
                                        <div
                                            style={timerCardStyles}
                                            className="px-2 py-1 text-center sm:px-[13px]"
                                        >
                                            <h4 className="font-Rubik text-[11px] font-bold text-white sm:text-sm">
                                                {minutes}
                                            </h4>
                                            <h5 className="-mt-1 font-Rubik text-[10px] font-semibold text-[#A9A6E3] sm:mt-0 sm:text-xs">
                                                Min
                                            </h5>
                                        </div>
                                        <div
                                            style={timerCardStyles}
                                            className="px-2 py-1 text-center sm:px-[13px]"
                                        >
                                            <h4 className="font-Rubik text-[11px] font-bold text-white sm:text-sm">
                                                {seconds}
                                            </h4>
                                            <h5 className="-mt-1 font-Rubik text-[10px] font-semibold text-[#A9A6E3] sm:mt-0 sm:text-xs">
                                                Sec
                                            </h5>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="mr-32 flex items-center justify-end sm:mr-[40%] md:mr-[35%] lg:mr-0 2xl:-mr-16">
                                    <Link
                                        className="relative bottom-3  cursor-pointer rounded-3xl border-2 border-white bg-[#655bff] px-3.5 py-1.5 text-sm font-semibold text-white hover:bg-gray sm:px-6 sm:py-2 sm:text-lg md:bottom-8 lg:bottom-2 lg:px-4 lg:py-1.5 lg:text-sm 2xl:bottom-1 2xl:px-6 2xl:py-2 2xl:text-[15px]"
                                        href="https://forms.gle/6uXDSwarrvzPsRM26"
                                        target="_blank"
                                    >
                                        Register Now
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default LegacyLayout;
