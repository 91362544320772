export const allCountries = [
    {
        id: 1,
        url: "bogo-june-24-mobile.jpg",
        styles: "fixed bottom-0 left-0 w-full md:hidden lg:hidden z-[9999]"
    },
    {
        id: 2,
        url: "bogo-june-24-tab.png",
        styles: "fixed bottom-0 left-0 hidden w-full md:block lg:hidden z-[9999]"
    },
    {
        id: 3,
        url: "bogo-june-24-pc.jpg",
        styles: "fixed bottom-0 left-0 hidden w-full lg:block z-[9999]"
    }
];

export const africaCountries = [
    {
        id: 1,
        url: "custom-offer-mobile.jpg",
        styles: "fixed bottom-0 left-0 w-full md:hidden lg:hidden"
    },
    {
        id: 2,
        url: "custom-offer-tab.jpg",
        styles: "fixed bottom-0 left-0 hidden w-full md:block lg:hidden"
    },
    {
        id: 3,
        url: "custom-offer-pc.jpg",
        styles: "fixed bottom-0 left-0 hidden w-full lg:block"
    }
];

export const europeCountries = [
    {
        id: 1,
        url: "turkey-mobile.jpg",
        styles: "fixed bottom-0 left-0 w-full md:hidden lg:hidden"
    },
    {
        id: 2,
        url: "turkey-tab.jpg",
        styles: "fixed bottom-0 left-0 hidden w-full md:block lg:hidden"
    },
    {
        id: 3,
        url: "turkey-pc.jpg",
        styles: "fixed bottom-0 left-0 hidden w-full lg:block"
    }
];
