/* eslint-disable @next/next/next-script-for-ga */
import { GoogleTagManager } from "@next/third-parties/google";
import Head from "next/head";
import { usePathname } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Provider } from "react-redux";
import LegacyLayout from "../components/layout/Layout";
import Layout from "../components/v2/Layout";
import { SOCIAL_MEDIA_DATA } from "../data/SocialMediaData";
import { store } from "../redux/store";
import "../styles/globals.css";

// posthog script
if (typeof window !== "undefined") {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host:
            process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
        person_profiles: "identified_only",
        capture_pageview: false,
        capture_pageleave: false,
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === "development") posthog.debug();
        }
    });
}

const LayoutList = {
    LegacyLayout: LegacyLayout,
    NewLayout: Layout
};

function MyApp({ Component, pageProps }) {
    const RenderLayout = LayoutList[Component?.layout] || LegacyLayout;
    // const gtmId = process.env.GTM_ID;
    const routeTitles = {
        "/404": "FundedNext | Page Not Found",
        "/about-us": "FundedNext | About us",
        "/affiliate-competition": "FundedNext | Affiliate Competition",
        "/affiliate": "FundedNext | Affiliate",
        "/aml-policy": "FundedNext | Aml Policy",
        "/chat": "FundedNext | Chat",
        "/competition/index": "FundedNext | Trading Competition",
        "/competition/registration":
            "FundedNext | Trading Competition Register",
        "/competition/verify-token": "FundedNext | Verify Token",
        "/contact": "FundedNext",
        "/cookie-policy": "FundedNext | Cookie Policy",
        "/economic-calendar": "FundedNext | Economic Calendar",
        "/evaluation-model": "FundedNext | Evaluation Model",
        "/express-model": "FundedNext | Express Model",
        "/hall-of-fame": "FundedNext | Hall of Fame",
        "/index": "FundedNext - Our Fund, Your Profit | Best Trading Firm",
        "/package-comparison": "FundedNext | Package Comparison",
        "/partners": "FundedNext | Affiliate Partners",
        // "/payment-partner": "FundedNext | Payment Partner",
        "/privacy-policy": "FundedNext | Privacy Policy",
        "/prop-trader-secrets": "FundedNext | Trader Secrets",
        "/propfirm": "FundedNext | Propfirm",
        "/proprietary-trading": "FundedNext | Trading",
        "/resource": "FundedNext | Resource",
        "/risk-disclosure": "FundedNext | Risk Disclosure",
        "/sqz": "FundedNext | Sqz",
        "/symbols": "FundedNext | Symbols",
        "/terms-of-service": "FundedNext | Terms of Service"
    };

    const pathname = usePathname();

    if (process.env.SHOW_LOGGER != 1) {
        console.log = (a) => {};
        console.error = (a) => {};
    }

    const domain = "https://fundednext.com";
    const canonicalUrl = `${domain}${pathname}`;

    return (
        <>
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                        !function(f,e,t,u,n,s,p){if(f.esk)return;n=f.esk=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f.___esk)f.___esk=n;
                        n.push=n;n.loaded=!0;n.queue=[];s=e.createElement(t);s.async=!0;s.src=u;
                        p=e.getElementsByTagName(t)[0];p.parentNode.insertBefore(s,p)}(window,document,'script',
                        'https://dsp-media.eskimi.com/assets/js/e/gtr.min.js?_=0.0.0.5');
                        esk('init', '32613');
                    `
                    }}
                />
                <title>
                    {routeTitles[pathname] ||
                        `FundedNext - Our Fund, Your Profit | Best Trading Firm`}
                </title>
                <meta
                    property="og:image"
                    content={
                        pathname === "/competition"
                            ? SOCIAL_MEDIA_DATA.image
                            : "https://fundednext.fra1.digitaloceanspaces.com/property-image.png"
                    }
                />
                <meta
                    name="description"
                    content="FundedNext is a trading firm for skilled traders worldwide! Join our funded trader program and start making money right away."
                />
                <meta
                    name="keywords"
                    content="funded trader program, trading firm, trader funding, forex funding, funded trading accounts"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=3.0,"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
                <meta
                    name="google-site-verification"
                    content="n-DxVspcwEy1bXxRaLkIDf6XRQUyxk4lS9p5PoemU68"
                />
                <link
                    rel="fav icon"
                    href="https://fundednext.fra1.digitaloceanspaces.com/favicon.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="shortcut icon"
                    href="https://fundednext.fra1.digitaloceanspaces.com/favicon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <link rel="canonical" href={canonicalUrl} />
            </Head>
            {/* <script
                dangerouslySetInnerHTML={{
                    __html: `
                (function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${gtmId}');
                `
                }}
            /> */}

            <PostHogProvider client={posthog}>
                <Provider store={store}>
                    <RenderLayout>
                        <Component {...pageProps} />
                        <GoogleTagManager gtmId={process.env.GTM_ID} />
                    </RenderLayout>
                </Provider>
            </PostHogProvider>
        </>
    );
}

export default MyApp;
